<script setup lang="ts">
import type { Database } from '~/types/database';

const supabase = useSupabaseClient<Database>()

const searchModelOpen = ref(false)

const groups = [
  {
    key: 'pkg-desc',
    label: (q: String) => q && `Packages matching “${q}”...`,
    search: async (q: String) => {
      let query = supabase
        .from('packages')
        .select('id, package, description')
        .or(`package.ilike.%${q}%,description.ilike.%${q}%`)
      const { data, error } = await query.limit(5)
      
      if (error) {
        console.error(error)
        return []
      }
      return data.map(item => ({
        id: item.id,
        label: item.package,
        suffix: item.description,
        to: `/packages/${item.id}`,
        type: 'Package'
      }))
    }
  },
  {
    key: 'sf01',
    label: (q: any) => q && `Qtrax Identifiers matching “${q}”...`,
    search: async (q: any) => {
      let query = supabase
        .from('qtrax')
        .select('id, sf01, sf24, sf25, qtrax_links(package_id, link_type)')
        .ilike('sf01', `%${q}%`)
      const { data, error } = await query.limit(5)
      if (error) {
        console.error(error)
        return []
      }
      return data.map(item => {
        const link = item.qtrax_links.find(link => link.link_type === 'IWP')
        if (!link) return null
        return {
          id: item.id,
          label: item.sf01,
          suffix: item.sf25 + ' - ' + item.sf24,
          type: 'Package',
          to: link.package_id ? `/packages/${link.package_id}` : null
        }
      }).filter(Boolean)
    }
  },
  {
    key: 'sf10',
    label: (q: any) => q && `Qtrax Drawings matching “${q}”...`,
    search: async (q: any) => {
      let query = supabase
        .from('qtrax')
        .select('id, sf10, sf24, sf25, qtrax_links(package_id, link_type)')
        .ilike('sf10', `%${q}%`)
      const { data, error } = await query.limit(5)
      if (error) {
        console.error(error)
        return []
      }
      return data.map(item => {
        const link = item.qtrax_links.find(link => link.link_type === 'IWP')
        if (!link) return null
        return {
          id: item.id,
          label: item.sf10,
          suffix: item.sf25 + ' - ' + item.sf24,
          type: 'Package',
          to: link.package_id ? `/packages/${link.package_id}` : null
        }
      }).filter(Boolean)
    }
  }
]

function onSelect(item: any) {
  console.log('Selected:', item)
  if (item.to) navigateTo(item.to)

  searchModelOpen.value = false
}defineShortcuts({
  meta_k: {
    usingInput: true,
    handler: () => {
      searchModelOpen.value = true
    }
  }
})
const { metaSymbol } = useShortcuts()
</script>

<template>
  <!-- <UDashboardSearchButton label="Search..." @click="searchModelOpen = !searchModelOpen" /> -->
   <UButton @click="searchModelOpen = !searchModelOpen" block variant="solid" size="md" color="gray" icon="i-heroicons-magnifying-glass" class="w-80">
    Search...
    <template #trailing>
      <div class="flex items-center gap-0.5 ml-4">
        <UKbd> {{ metaSymbol }}</UKbd>
        <UKbd>K</UKbd>
      </div>
      </template>
  </UButton>
    <UModal v-model="searchModelOpen"
      :ui="{'width' : 'sm:max-w-7xl'}"
    >
      <UCommandPalette :groups @update:model-value="onSelect" placeholder="Search for packages, tags, drawings, etc..." />
    </UModal>
</template>
