export interface Role {
  label: string
  value: string
}
export interface NavigationItem {
    label: string
    icon?: string
    to?: string
    children?: NavigationItem[]
    roles?: Role[]
    defaultOpen?: boolean
}
export type RolesType = Record<string, Role>
export const Roles: Record<string, Role> = {
  admin: {
    label: 'Admin',
    value: 'admin'
  },
  planner: {
    label: 'Planner',
    value: 'planner'
  },
  testing: {
    label: 'Testing Group',
    value: 'testing'
  },
  viewer: {
    label: 'Viewer',
    value: 'viewer'
  },
  deactivated: {
    label: 'Deactivated',
    value: 'deactivated'
  }
}

export function getRoleFromValue(value: string) {
  return Object.values(Roles).find(role => role.value === value)
}

export const Navigation: NavigationItem[] = [
  { label: 'Dashboard', icon: 'i-heroicons-chart-pie', to: '/', roles: [Roles.planner, Roles.testing, Roles.viewer] },
  { label: 'Planner Tools', icon: 'i-fa6-solid-screwdriver-wrench', children: [
      { label: 'Ebuilt Constraints', to: '/planners/ebuilts', icon: 'i-fa6-solid-file-circle-xmark', roles: [Roles.planner] },
      { label: 'Pipe Testing', to: '/planners/pipe-testing', icon: 'i-mdi-pipe-leak', roles: [Roles.testing, Roles.planner] },
      { label: 'Aconex Package Updates', to: '/planners/package-updates', icon: 'i-fa6-solid-file-circle-exclamation', roles: [Roles.planner] },
    ],
    defaultOpen: true,
    roles: [Roles.planner, Roles.testing]
  },
  { label: 'Packages', icon: 'i-fa6-solid-box', to: '/packages', roles: [Roles.planner, Roles.testing, Roles.viewer] },
    { label: 'Data', icon: 'i-fa6-solid-database', children: [
        { label: 'Qtrax', to: '/data/qtrax', roles: [Roles.planner, Roles.testing, Roles.viewer] },
        { label: 'PCN', to: '/data/pcn', roles: [Roles.planner, Roles.testing, Roles.viewer] },
        { label: 'Schedule', to: '/data/schedule', roles: [Roles.planner, Roles.testing, Roles.viewer] },
        { label: 'Rates', to: '/data/rates', roles: [Roles.planner, Roles.testing, Roles.viewer] },
        { label: 'Aconex', to: '/data/aconex', roles: [Roles.planner, Roles.testing, Roles.viewer] },
        { label: 'Upload Data', to: '/data/upload', roles: [Roles.planner, Roles.testing, Roles.viewer] },
    ],
      roles: [Roles.planner, Roles.testing]
    },
    { label: 'Settings', icon: 'i-heroicons-cog', to: '/settings', roles: [Roles.planner, Roles.testing, Roles.viewer] },
    { label: 'About', icon: 'i-heroicons-information-circle', to: '/about' },
]