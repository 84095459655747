<template>
	<UChip class="w-1/2" inset :ui="{background: 'animate-ping'}">
		<UButton color="violet" block :disabled="!supabaseUser" @click="onlineUserModalOpen = true">
			Online: {{ onlineUsers.length }}
		</UButton>
	</UChip>
	<UModal v-model="onlineUserModalOpen">
		<UCard>
			<template #header>
				<div class="flex flex-row justify-between items-center">
					<h2 class="font-semibold text-xl">Online Users</h2>
					<UButton variant="ghost" icon="i-fa6-solid-xmark" color="red" size="lg" @click="onlineUserModalOpen = false" />
				</div>
			</template>
			<template #default>
				<div class="overflow-auto flex flex-wrap gap-4 justify-around">
					<div v-for="user in onlineUsers" class="flex flex-row gap-2 items-center">
						<GlobalAvatar :user="user" />
						<div class="flex flex-col">
							<p class="font-semibold">{{ user.name }}</p>
							<p class="text-sm">{{ user.role.slice(0, 1).toUpperCase() + user.role.slice(1) }}</p>
						</div>
					</div>
				</div>
			</template>
		</UCard>
	</UModal>
</template>

<script setup lang="ts">
const supabaseUser = useSupabaseUser()
const onlineUserModalOpen = ref(false)
const { onlineUsers, getOnlineUsers } = useUserPresence()

// Fetch initial data
await getOnlineUsers()
</script>