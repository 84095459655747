import payload_plugin_9jepSpZ2be from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_nD8rN07I8l from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8ef7s98CB2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Q5Nsplm2Ey from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_w5AKLAmZH0 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_4EeMExRMij from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_RNk9NeE0ep from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yF8Nn165Pz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6TnVdUN68q from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_zVOOiXLlBq from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_u5KBSE3IYM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_H3W6rbxWc8 from "/opt/buildhome/repo/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_pinia@2.3.0/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import slideovers_GITOL0V6yb from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_typescript@5.7.3_vite@6.0.7_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_BfGw1FlsIt from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_typescript@5.7.3_vite@6.0.7_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_BIdT1UP0fE from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_typescript@5.7.3_vite@6.0.7_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_eNelkHx7DD from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_sJ6KGSO4y7 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_vite@6.0.7_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_4pPydh1Val from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_typescript@5.7.3_vite@6.0.7_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_0BfDn8X6XW from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_typescript@5.7.3_vite@6.0.7_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_on7AR9IaH2 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_typescript@5.7.3_vite@6.0.7_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import chartjs_1wC9lAM0Tp from "/opt/buildhome/repo/plugins/chartjs.ts";
export default [
  payload_plugin_9jepSpZ2be,
  revive_payload_client_nD8rN07I8l,
  unhead_8ef7s98CB2,
  router_Q5Nsplm2Ey,
  supabase_client_w5AKLAmZH0,
  payload_client_4EeMExRMij,
  navigation_repaint_client_RNk9NeE0ep,
  check_outdated_build_client_yF8Nn165Pz,
  chunk_reload_client_6TnVdUN68q,
  plugin_vue3_zVOOiXLlBq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_u5KBSE3IYM,
  plugin_H3W6rbxWc8,
  slideovers_GITOL0V6yb,
  modals_BfGw1FlsIt,
  colors_BIdT1UP0fE,
  plugin_client_eNelkHx7DD,
  plugin_sJ6KGSO4y7,
  scrollbars_client_4pPydh1Val,
  presets_0BfDn8X6XW,
  variables_on7AR9IaH2,
  chartjs_1wC9lAM0Tp
]