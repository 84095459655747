<script setup lang="ts">
import GlobalOnlineUsersButton from "~/components/global/GlobalOnlineUsersButton.vue";
import type { DashboardSidebarLink } from '#ui-pro/types'

const supabase = useSupabaseClient()
const userStore = useUserStore()
const user = useSupabaseUser()

const links = ref<DashboardSidebarLink[]>([])

//recreate the computed property, but assign it to links.value instead
function getLinks() {
  console.log('getLinks');
  
  if (!user.value) {
    // Show login link plus any pages without role requirements
    links.value = [
      { label: 'Login', icon: 'i-heroicons-user-circle', to: '/login' },
      ...Navigation.filter(item => !item.roles)
    ]
    return
  }

  const userRole = userStore.user.role?.value || ''

  // If a user is an admin, show everything
  if (userRole === Roles.admin.value) {
    links.value = Navigation
    return
  }

  // Helper function to check if user has required role
  const hasRequiredRole = (roles?: Role[]) => {
    if (!roles) return true // If no roles specified, everyone can see it
    return roles.some(role => role.value === userRole)
  }

  // Filter the list
  links.value = Navigation.reduce<DashboardSidebarLink[]>((acc, item) => {
    // Always include items without roles
    if (!item.roles) {
      acc.push({ ...item })
      return acc
    }

    // Check if user has access to the parent item
    if (!hasRequiredRole(item.roles)) return acc

    // Create a copy of the item
    const filteredItem = { ...item }

    // If item has children, filter them
    if (item.children) {
      const filteredChildren = item.children.filter(child => 
        !child.roles || hasRequiredRole(child.roles)
      )

      // Only include parent if it has accessible children
      if (filteredChildren.length > 0) {
        filteredItem.children = filteredChildren
        acc.push(filteredItem)
      }
    } else {
      // If no children, just add the item
      acc.push(filteredItem)
    }

    return acc
  }, [])
}
getLinks()


const logout = async() => {
	await useSupabaseClient().auth.signOut()
	useToast().add({title: 'Success', description: 'Logout successful', color: 'green', icon: 'i-heroicons-check-circle'})
	await navigateTo('/login')
}

const { data } = supabase.auth.onAuthStateChange((event, session) => {
	setTimeout(async () => {
	await userStore.getUser()
    getLinks()
	if (event === 'SIGNED_IN') {
		const { updatePresence } = useUserPresence()
		await updatePresence()
	}
	}, 100)
})
</script>

<template>
	<UDashboardLayout>
		<UDashboardPanel :width="250" collapsible :resizable="{ min: 200, max: 400, storage: 'local' }"  >
			<UDashboardNavbar>
				<template #center>
					<h1 class="font-semibold text-xl text-center text-green-600 dark:text-green-400">
						Project Controls Reporting
					</h1>
				</template>
			</UDashboardNavbar>
			<UDashboardSidebar>
				<UDashboardSidebarLinks :level="2" :links="links" />
				<template #footer>
					<div class="flex flex-row w-full gap-2 items-center justify-center">
						<GlobalOnlineUsersButton />
						<UButton color="red" block icon="i-heroicons-arrow-left-start-on-rectangle" class="w-1/2" v-if="user" @click="logout">Logout</UButton>
						<UButton color="green" block icon="i-heroicons-arrow-left-end-on-rectangle" class="w-1/2" v-else to="/login">Login</UButton>
					</div>
				</template>
			</UDashboardSidebar>
		</UDashboardPanel>
		<UDashboardPage>
			<UDashboardPanel grow>
				<UDashboardNavbar>
					<template #center>
						<GlobalSearch />
					</template>
					<template #right>
						<ClientOnly>
							<UColorModeSelect />
						</ClientOnly>
					</template>
				</UDashboardNavbar>
				<GlobalFileUploadAlert />
				<UDashboardPanelContent class="w-full mx-auto">
					<slot />
				</UDashboardPanelContent>
			</UDashboardPanel>
		</UDashboardPage>
	</UDashboardLayout>
	<UNotifications />
	<UModals />
</template>