import type { Database } from "~/types/database"

interface PresenceUser {
    name: string
    role: string
    id: string
    email: string
    status: 'online' | 'away' | 'offline'
  }

export const useUserPresence = () => {
    const supabase = useSupabaseClient<Database>()
    const user = useSupabaseUser()
    const isFirstVisit = ref(true)
    const onlineUsers = ref<PresenceUser[]>([])
  
    const updatePresence = async () => {
      if (!user.value) return
  
      try {
        const updates: {
          last_activity: string
          session_start?: string
        } = {
          last_activity: new Date().toISOString()
        }
  
        if (isFirstVisit.value) {
          updates.session_start = new Date().toISOString()
          isFirstVisit.value = false
        }
  
        const { data: existingPresence } = await supabase
          .from('user_presence')
          .select()
          .eq('user_id', user.value.id)
          .single()
  
        if (!existingPresence) {
          await supabase.from('user_presence').insert({
            user_id: user.value.id,
            ...updates
          })
        } else {
          await supabase
            .from('user_presence')
            .update(updates)
            .eq('user_id', user.value.id)
        }
      } catch (error) {
        console.error('Error updating user presence:', error)
      }
    }

    const getOnlineUsers = async () => {
        try {
          const oneHourAgo = new Date(new Date().getTime() - 60 * 60 * 1000)
    
          const { data, error } = await supabase
            .from('user_presence')
            .select(`
              user_id,
              last_activity,
              users (
                id,
                name,
                role,
                email
              )
            `)
            .gt('last_activity', oneHourAgo.toISOString())
            .order('last_activity', { ascending: false })
    
          if (error) throw error
    
          onlineUsers.value = data.map(presence => {
            return {
              id: presence.users.id,
              name: presence.users.name,
              role: presence.users.role,
              email: presence.users.email,
              status: getPresenceStatus(presence.last_activity?.toString() || new Date().toISOString())
            }
          })
    
          return onlineUsers.value
        } catch (error) {
          console.error('Error fetching online users:', error)
          return []
        }
      }

      const getPresenceByUserId = async (userId: string) => {
        const { data, error } = await supabase
          .from('user_presence')
          .select('last_activity, users (name, role, id, email)')
          .eq('user_id', userId)
          .single()
    
        if (error) throw error
    
        return {
          name: data.users.name,
          role: data.users.role,
          id: data.users.id,
          email: data.users.email,
          status: data.last_activity ? getPresenceStatus(data.last_activity) : 'offline'
        }
      }
  
      
    onMounted(() => {
        getOnlineUsers()
        const interval = setInterval(getOnlineUsers, 30000)
        onUnmounted(() => clearInterval(interval))

      watch(user, async (newUser) => {
        if (newUser) {
          await updatePresence()
        }
      }, { immediate: true })
    })
  
    return {
      updatePresence,
        getOnlineUsers,
        onlineUsers,
        getPresenceByUserId
    }
  }

  const getPresenceStatus = (lastActivity: string) => {
    const now = new Date()
    const fifteenMinutesAgo = new Date(now.getTime() - 15 * 60 * 1000)
    const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000)
  
    const lastActivityDate = new Date(lastActivity)
    let status: 'online' | 'away' | 'offline'
    if (lastActivityDate > fifteenMinutesAgo) status = 'online'
    else if (lastActivityDate > oneHourAgo) status = 'away'
    else status = 'offline'
  
    return status
  }