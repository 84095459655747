import { type Database } from "~/types/database"
import { getRoleFromValue } from "~/utils/permissions"

export const useUserStore = defineStore('user', () => {

    const user = reactive<User>({
        name: '',
        role: undefined,
        id: '',
        email: ''
    })

    async function getUser() {
        const supabaseUser = useSupabaseUser()
        if (!supabaseUser.value) return
        const supabase = useSupabaseClient<Database>()
        const { data, error } = await supabase
            .from('users')
            .select('*')
            .eq('id', supabaseUser.value.id)
            .maybeSingle()

        if (error) throw error
        if (data) {
            user.name = data.name
            user.role = getRoleFromValue(data.role)
            user.id = data.id
            user.email = data.email
        }
    }

    return { user, getUser }
})

interface User {
    name: string
    role: Role | undefined
    id: string
    email: string
}
