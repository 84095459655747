import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_typescript@5.7.3_vite@6.0.7_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}